import React from "react";

import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";

import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields
} from "./config/config-helper";



import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { NumericFormat } from "react-number-format";

import * as dateFns from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function formatDateTime(value) {
  const tzDate = utcToZonedTime(value, "Asia/Tokyo");

  if (!dateFns.isValid(tzDate)) return "";

  return dateFns.format(tzDate, "yyyy/MM/dd HH:mm:ss");
};



const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase
});
const config = {
  searchQuery: {
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig()
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true
};

export default function App() {
  return (
    <SearchProvider config={config}>
      <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
        {({ wasSearched }) => {
          return (
            <Router>
              <div className="App">
                <ErrorBoundary>
                  <Layout
                    header={<SearchBox autocompleteSuggestions={true} />}
                    sideContent={
                      <div>
                        {/*
                        {wasSearched && (
                          <Sorting
                            label={"Sort by"}
                            sortOptions={buildSortOptionsFromConfig()}
                          />
                        )}
                        */}
                        {getFacetFields().map(field => (
                          <Facet key={field} field={field} label={field} />
                        ))}

                        <fieldset className="sui-facet">
                          <legend className="sui-facet__title">表示モード</legend>
                          <div>
                            <Routes>
                              <Route exact path="/" element={<ChangePathRootChild />} />
                              <Route path="/raw/" element={<ChangePathRawChild />} />
                            </Routes>
                          </div>
                        </fieldset>
                      </div>
                    }
                    bodyContent={
                      <Routes>
                        <Route exact path="/" element={<RootChild />} />
                        <Route path="/raw/" element={<RawChild />} />
                      </Routes>
                    }
                    bodyHeader={
                      <React.Fragment>
                        {wasSearched && <PagingInfo />}
                        {wasSearched && <ResultsPerPage />}
                      </React.Fragment>
                    }
                    bodyFooter={<Paging />}
                  />
                </ErrorBoundary>
              </div>
            </Router>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}

function ChangePathRootChild() {
  return (
    <div className="sui-link-facet">
      <span>サービス表示</span>
      <span><a className="change-path" href="/raw">raw表示</a></span>
    </div>
  );
}

function ChangePathRawChild() {
  return (
    <div className="sui-link-facet">
      <span><a className="change-path" href="/">サービス表示</a></span>
      <span>raw表示</span>
      <div className="raw-comment">（表示要素以外にmetaタグ一覧を保持）</div>
    </div>
  );
}

function RootChild() {
  return (
    <div className="mode-disp">
      <Results
        resultView={CustomResultView}
        titleField={getConfig().titleField}
        urlField={getConfig().urlField}
        thumbnailField={getConfig().thumbnailField}
        shouldTrackClickThrough={true}
      />
    </div>
  );
}

function RawChild() {
  return (
    <div className="mode-raw">
      <Results
        titleField={getConfig().titleField}
        urlField={getConfig().urlField}
        thumbnailField={getConfig().thumbnailField}
        shouldTrackClickThrough={true}
      />
    </div>
  );
}





const CustomResultView = ({ result, onClickLink }) => (
  <li className="sui-result">
    <div className="sui-result__header">
      <a
        onClick={onClickLink}
        href={result['doc.url'].raw}
        dangerouslySetInnerHTML={{ __html: result['doc.title'].snippet }}
      ></a>
    </div>
    <div className="sui-result__url">
      <a
        onClick={onClickLink}
        href={result['doc.url'].raw}
        dangerouslySetInnerHTML={{ __html: result['doc.url'].raw }}
      ></a>
    </div>
    <div className="sui-result__body">
      {/* use 'raw' values of fields to access values without snippets */}
      { result['doc.thumbnail'].raw &&
        <div className="sui-result__image">
          <a
            onClick={onClickLink}
            href={result['doc.url'].raw}
          >
            <img src={result['doc.thumbnail'].raw} alt="" />
          </a>
        </div>
      }
      {/* Use the 'snippet' property of fields with dangerouslySetInnerHtml to render snippets */}
      <div
        className="sui-result__details"
        dangerouslySetInnerHTML={{ __html: result['doc.content'].snippet }}
      ></div>
    </div>
    <div className="sui-result__info">
      PV数：直近7日 <NumericFormat
          value={result['last7days_pv'].raw}
          thousandSeparator=","
          displayType="text"
      />PV | 直近14日 <NumericFormat
          value={result['last14days_pv'].raw}
          thousandSeparator=","
          displayType="text"
      />PV | 直近30日 <NumericFormat
          value={result['last30days_pv'].raw}
          thousandSeparator=","
          displayType="text"
      />PV
      &nbsp;&nbsp;
      [クロール日時：{ formatDateTime(result['doc.updated_at'].raw) }]
    </div>
  </li>
);
